.loading {
    position: absolute;
    width: 108px;
    height: 108px;
    left: 50%;
    top: 50%;
    margin-left: -54px;
    margin-top: -54px;

    span {
        position: absolute;
        width: 9%;
        height: 9%;
        background: #2c302c;
        border-radius: 50%;
        animation: loadingAnimation 2.6s infinite;
        opacity: 0.4;

        &:nth-child(1) {
            left: 45.5%;
            top: 0; }

        &:nth-child(2) {
            left: 64.8%;
            top: 5.7%;
            animation-delay: 0.1s; }

        &:nth-child(3) {
            left: 80.5%;
            top: 18.5%;
            animation-delay: 0.2s; }

        &:nth-child(4) {
            left: 91%;
            top: 36.1%;
            animation-delay: 0.3s; }

        &:nth-child(5) {
            left: 91%;
            top: 56.4%;
            animation-delay: 0.4s; }

        &:nth-child(6) {
            left: 80.5%;
            top: 72.5%;
            animation-delay: 0.5s; }

        &:nth-child(7) {
            left: 64.8%;
            top: 85.3%;
            animation-delay: 0.6s; }

        &:nth-child(8) {
            left: 45.5%;
            top: 91%;
            animation-delay: 0.7s; }

        &:nth-child(9) {
            left: 26.2%;
            top: 85.3%;
            animation-delay: 0.8s; }

        &:nth-child(10) {
            left: 10.5%;
            top: 72.5%;
            animation-delay: 0.9s; }

        &:nth-child(11) {
            left: 0;
            top: 56.4%;
            animation-delay: 1s; }

        &:nth-child(12) {
            left: 0;
            top: 36.1%;
            animation-delay: 1.1s; }

        &:nth-child(13) {
            left: 10.5%;
            top: 18.5%;
            animation-delay: 1.2s; }

        &:nth-child(14) {
            left: 26.2%;
            top: 5.7%;
            animation-delay: 1.3s; } }

    p {
        display: table-cell;
        width: 108px;
        height: 108px;
        font-size: 15px;
        text-transform: uppercase;
        text-align: center;
        vertical-align: middle; }

    &:global(.small) {
        width: 54px;
        height: 54px;
        margin-left: -26px;
        margin-top: -26px;

        p {
            display: none; } }

    @media screen and ( max-width: 480px ) {
        width: 54px;
        height: 54px;
        margin-left: -26px;
        margin-top: -26px;

        p {
            display: none; } } }

@keyframes loadingAnimation {
    0% {
        opacity: 0.4; }
    50% {
        opacity: 1; }
    100% {
        opacity: 0.4; } }
