.cookie {
    position: fixed;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0,0,0,0.9);
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 15px;

     :global {
        p a,
        .accept {
            color: #E8247E; }


        p a:hover,
        .accept:hover {
            text-decoration: underline; }


        .accept {
            display: inline-block;
            margin-top: 10px;
            font-size: 20px;
            cursor: pointer; } } }
