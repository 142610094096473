.next {
     :global {
        h4 {
            font-weight: 500;
            text-transform: uppercase; }

        .title {
            background: #fff;
            text-align: center;

            .inner {
                padding-bottom: 2%; } }

        .project {
            background: linear-gradient(to bottom, white 0%, white 50.0%, rgba(220, 219, 212, 100%) 50%, rgba(220, 219, 212, 100%) 100%);

            .table {
                background: #E1E2E1; }

            .image {
                padding-bottom: 39.95%;

                > div {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: center center; } }

            .text {
                padding: 3.5% 9% 3.5% 6.1%;
                vertical-align: middle;

                .logo {
                    max-width: 30%;

                    + h5 {
                        font-weight: 400;
                        margin-top: 5%;
                        font-family: 'Exo 2', sans-serif; } }

                .icon {
                    position: absolute;
                    top: 50%;
                    right: 7.5%;
                    transform: translateY(-50%); } }

            .inner:hover {
                .icon {
                    background: #E8247E; }

                .stroke {
                    stroke: #fff; } } }


        .back {
            background: rgb(220, 219, 212);

            .center > div {
                padding: 3.8% 0; }

            a {
                color: #E8247E;
                text-transform: uppercase;

                .icon {
                    float: left;
                    margin-right: 24px;
                    transform: rotate(180deg); }

                &:hover {
                    .icon {
                        background: #E8247E;

                        .stroke {
                            stroke: #fff; } } } } }

        @media screen and ( max-width: 580px ) {
            .project .center.main {
                padding: 0; }

            .table {
                .col {
                    display: block;
                    padding-left: 25px;
                    padding-right: 25px; }

                .image {
                    padding-bottom: 0;
                    background: linear-gradient(180deg, #fff 0, #fff 50.0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0)); }

                .image > div {
                    position: relative;
                    padding-bottom: 73.89%; }

                .text {
                    padding-top: 6%;
                    padding-bottom: 5%;
                    text-align: center;

                    .icon {
                        margin-top: 5%;
                        display: inline-block;
                        position: relative;
                        right: inherit;
                        transform: none; } } }

            .back {
                display: none; } } } }

