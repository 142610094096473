.logos {
    background: #B9C8D1;
    margin-bottom: 0;

     :global {
        .inner {
            padding: 3.164% 0; }

        h2 {
            margin-bottom: 2%;
            text-align: center;
            color: #fff; }

        .container {
            overflow: hidden;
            width: 90%;
            margin: 0 5%; }

        .slider {


            > div {
                display: flex;
                flex-wrap: wrap;

                > div {
                    flex: 0 0 25%;

                    > div {
                        position: relative;
                        margin: 8% 16%;
                        padding-bottom: 30%;
                        background-size: contain;
                        background-position: center center;
                        background-repeat: no-repeat; } } } } }

    &:global(.slider) {
         :global {
            .container {
                position: relative;
                padding-bottom: 14.88%;
                margin: 0;
                width: 100%; }

            .slider {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                transition: transform 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);

                > div {
                    flex-wrap: nowrap;

                    > div {
                        flex: 0 0 33.33%; } } } } } }




