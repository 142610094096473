.overlay {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #fdfdfd;
    opacity: 0;
    animation: fadeIn 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000) forwards;
    will-change: opacity, transform;

    &:global(.out) {
        animation: fadeOut 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000) forwards; }

     :global {
        .actions {
            position: absolute;
            z-index: 10;
            background-color: rgba(255,255,255, 0.6);
            top: 11px;
            right: 11px; }

        .close {
            position: relative;
            display: block;
            float: left;
            width: 24px;
            height: 24px;
            background: url(../../../img/close.svg) no-repeat center center;
            cursor: pointer; }

        .fullscreen {
            position: relative;
            display: block;
            float: left;
            padding-right: 20px;
            padding-left: 5px;
            margin-right: 5px;
            background: url(../../../img/arrow2.svg) no-repeat right 6px;
            background-size: 11px 11px;
            font-size: 15px;
            line-height: 24px;
            text-transform: uppercase;
            cursor: pointer;
            color: #000; }

        .gallery {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;

            ul,
            ul li {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%; }

            ul {
                transition: transform 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);
                -webkit-transition: transform 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);
                -moz-transition: transform 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);
                -o-transition: transform 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);

                li {
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;

                    iframe {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%; }

                    > div {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center center; } }

                &.touching {
                    li {
                        transition-duration: 0s;
                        -webkit-transition-duration:all 0s {}
                        -moz-transition-duration:all 0s {}
                        -o-transition-duration:all 0s {} } } }

            .button {
                position: absolute;
                z-index: 10;
                top: 50%;

                &.previous {
                    left: 21px;
                    -moz-transform: rotate(180deg) translateY(50%);
                    -ms-transform: rotate(180deg) translateY(50%);
                    -o-transform: rotate(180deg) translateY(50%);
                    -webkit-transform: rotate(180deg) translateY(50%);
                    transform: rotate(180deg) translateY(50%); }

                &.next {
                    right: 21px;
                    transform: translateY(-50%); } }

            .buttons {
                position: absolute;
                right: 21px;
                bottom: 21px;

                > span + span {
                    margin-top: 21px; } }

            > .info {
                position: absolute;
                z-index: 11;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.8);

                > div {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    padding: 46px;
                    transform: translate(-50%, -50%);
                    background: #dae4ea;
                    text-align: center; }

                h4 {
                    margin: 0 0 20px 0;
                    font-size: 20px;
                    line-height: 25px;
                    text-transform: uppercase; }

                p {
                    font-size: 15px;
                    line-height: 23px; } }

            .state {
                position: absolute;
                z-index: 1;
                width: 100px;
                left: 50%;
                margin-left: -50px;
                text-align: center;
                bottom: 15px;
                color: #000; } } } }


@keyframes fadeIn {
    0% {
        opacity: 0.3;
        transform: scale(1.8); }

    50% {
        opacity: 1; }

    100% {
        opacity: 1;
        transform: none; } }


@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: none; }

    100% {
        opacity: 0;
        transform: scale(1.4); } }
