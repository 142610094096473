.footer {
    background: #f4f3f0;
    font-size: 15px;
    line-height: 22px;

     :global {
        .inner {
            padding: 5% 0 5.2% 0;

            > div {
                position: relative; } }

        .text {
            float: left;
            position: relative;
            text-transform: uppercase;

            .table {
                width: 700px; }


            a[href^="mailto:"]:hover {
                text-decoration: underline; } }

        .navigation.social {
            position: absolute;
            right: 0px;
            bottom: -3px;
            height: 30px;

            a + a {
                margin-left: 12px; } }

        .navigation.main {
            position: absolute;
            right: 141px;
            bottom: 0;
            height: 22px;
            border-right: solid 2px #2e2821;

            a:hover span {
                text-decoration: underline; } }

        .top {
            position: fixed;
            z-index: 100;
            right: 50%;
            bottom: 30px;
            margin-right: -717px;
            transform: rotate(-90deg) translateY(-100%);
            transition: 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);
            transition-property: opacity, bottom;
            opacity: 0;

            &.show {
                opacity: 1; }

            &.bottom {
                bottom: 170px; } } }

    @media screen and ( max-width: 1450px ) {
         :global {
            .top {
                right: 3.958333333333333333333333333333%;
                margin-right: 0;
                transform: rotate(-90deg) translateY(0);

                &.bottom {
                    bottom: 170px; } } } }

    @media screen and ( max-width: 900px ) {

        text-align: center;
        margin-bottom: 215px;

         :global {
            .inner {
                padding-top: 35px;
                padding-bottom: 30px; }

            .text,
            .text .table,
            .navigation.social,
            .navigation.main {
                position: relative;
                float: left;
                width: 100%; }

            #footer .navigation.social {
                height: auto;
                margin-top: 42px; }

            .navigation.social a {
                width: 45px;
                height: 45px; }

            .navigation.social a + a {
                margin-left: 28px; }


            .navigation.main {
                margin-top: 36px;
                right: inherit;
                border-right: inherit; }

            .top {
                right: 50%;
                transform: rotate(-90deg) translateY(+50%);
                bottom: 40px;

                &.bottom {
                    bottom: 90px; } } } }

    @media screen and ( max-width: 663px ) {

         :global {
            .text .table .col {
                display: block; }


            .text .table .col + .col {
                margin-top: 40px; } } } }
