.media {
    position: relative;

     :global {
        .inner {
            padding-bottom: 4.36%; }

        .title {
            position: relative;
            padding-bottom: 4.36%; }

        h2 {
            float: left;
            width: 100%;
            text-align: center;
            margin-top: 4.36%; }

        span.fullscreen {
            position: absolute;
            right: 0;
            bottom: 8px;
            padding-right: 20px;
            background: url(../../../img/arrow2.svg) no-repeat right 5px;
            background-size: 11px 11px;
            font-size: 15px;
            line-height: 22px;
            text-transform: uppercase;
            cursor: pointer; }

        .close {
            position: absolute;
            z-index: 10;
            width: 24px;
            height: 24px;
            top: 13px;
            right: 13px;
            background: url(../../../img/close.svg) no-repeat center center;
            cursor: pointer; }

        .media {
            position: relative;

            > div {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                &.iframe {
                    background: #000; }

                &.image {
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center; }

                &.fullscreen {
                    z-index: 101;
                    position: fixed;
                    background-color: #fff;

                    .mute {
                        display: none; } }

                .mute {
                    display: block;
                    position: absolute;
                    z-index: 10;
                    right: 0.8%;
                    top: 1.5%;
                    width: 20px;
                    height: 20px;
                    cursor: pointer; } } }



        iframe,
        video {
            position: absolute;
            border: none;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%; }

        .play {
            position: absolute;
            z-index: 9;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%; } } }
